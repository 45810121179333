#root{
  display: flex;
  color: #001133;
}
.content{
  overflow-x: hidden;
  width: 100%;
  line-height: 1.8;
  font-size: 14px;
}
.kv-left{
  display: none;
}
.kv-right{
  display: none;
}

section{
  padding: 0 16px;
}
a{
  color: rgb(239,83,31);
}
h2{
  text-align: center;
  font-size: 18px;
  position: relative;
  margin: 0px 0 30px;
  /* padding-top: 30px; */
}
hr{
  background-image: url(./common/img/heading-bg.png);
  height: 90px;
  width: 300px;
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-position-y: center;
}
p:nth-child(n+1){
  margin-top: 1em; 
}

/*
 * 表形式
 */
dl.styled-dl{
  display: grid;
  grid-template-columns: auto 20px auto; /* 2列レイアウト */
  gap: 0px; /* 罫線の連続性を確保するためにギャップをなしに */
  border-collapse: collapse; /* 罫線を繋げる */
  width: 100%;
}
.styled-dl dt, .styled-dl dd {
  padding: 10px;
  position: relative; /* 疑似要素を相対配置するために必要 */
}
.styled-dl dt {
  font-weight: bold;
  text-align: left; /* 左揃え */
  padding: 4px;
  border-bottom: 1px solid #ea0019; /* 任意の罫線 */
  /* margin-bottom: 12px; */
}
.styled-dl dd {
  margin: 0;
  padding: 4px;
  border-bottom: 1px solid #ea0019;
  /* margin-bottom: 12px; */
}
.styled-dl dd.border {
  margin: 8px 8px;
  padding: 0px;
  border-bottom: none;
  border-right: 1px solid #ea0019;
}
.styled-dl dt:last-of-type, .styled-dl dd:last-of-type{
  border-bottom: none;
}

@media all and (min-width:768px){
  section{
    padding: 0;
  }
  .content{
    overflow-x: visible;
    width: 34%;
    min-width: 375px;
    margin: auto;
  }
  .kv-left{
    height: 100vh;
    position: fixed;
    display: block;
    width: 33%;
    background-size: 150%;
    background-repeat: no-repeat;
    background-position-x: 150%;
    background-position-y: center;
  }
  .kv-right{
    height: 100vh;
    position: fixed;
    display: block;
    width: 33%;
    right: 0;
    background-size: 150%;
    background-repeat: no-repeat;
    background-position-x: -50%;
    background-position-y: center;
  }
}
