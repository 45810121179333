@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}
.loading{
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
}
.loading img {
  width: 10%;
  animation: rotate 6s linear infinite; /* 2秒で360度回転 */
}
