header{
  display: flex;
  width: 100%;
  aspect-ratio: 2;
  flex-direction: column;
  justify-content: center;
}
header .logo{
  width: 15%;
  margin: 0 auto;
}
header .logo-text{
  width: 100%;
  text-align: center;
  font-size: 6vw;
  color: #ea0019;
  line-height: 1;
  margin-top: 1vw;
}
@media all and (min-width:768px){
  header .logo-text{
    font-size: 2vw;
  }
}

